<template>
  <div v-if="social != null">
    <v-btn
      v-for="(button, idx) in buttons"
      :key="idx"
      :class="`${bg} mx-1`"
      tag="a"
      target="_blank"
      :href="button.url"
      icon
      small
    >
      <v-icon
        :color="color"
        small
      >
        {{ button.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    bg: String,
  },
  data: () => ({
    buttons: [
      { icon: 'fab fa-facebook-f', url: null },
      { icon: 'fab fa-instagram', url: null },
      { icon: 'fab fa-youtube', url: null },
    ],
    social: null,
  }),
  methods: {
    redirectSite(url) {
      location.href = url
    },
    getSocial() {
      this.$api
        .get(`contact/socials`)
        .then((res) => {
          this.social = res.data.data
          this.buttons[0].url = this.social.link_facebook
          this.buttons[1].url = this.social.link_instagram
          this.buttons[2].url = this.social.link_youtube
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  created() {
    this.getSocial()
  },
}
</script>

<style></style>
